import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { selectOrders } from "../../store/orders/orders.selector";
import { fetchOrdersList } from "../../store/orders/orders.api";
import {
  selectProfileId,
  selectProfileName,
} from "../../store/profile/profile.selector";
import dayjs from "dayjs";
import { selectCheckoutExpiry } from "../../store/cart/cart.selector";
import {
  addCheckoutExpiry,
  clearCheckoutInfo,
} from "../../store/cart/cart.slice";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../store/store";

const CheckoutSuccessPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();

  const restaurantName = useSelector(selectProfileName, shallowEqual);
  const restaurantId = useSelector(selectProfileId, shallowEqual);
  const orders = useSelector(selectOrders, shallowEqual);
  const checkoutExpiry = useSelector(selectCheckoutExpiry, shallowEqual);
  const loading = useSelector((state: RootState) => state.orders.loading, shallowEqual);

  const onCreateNewOrderHandler = () => {
    dispatch(clearCheckoutInfo());
    navigate(`/${restaurantId}`);
  };

  const storeOrderId = (orderId: string): void => {
    const storedOrderIds = localStorage.getItem("orderIds") as string;
    const orderIdsArray: string[] = storedOrderIds
      ? JSON.parse(storedOrderIds)
      : [];
    if (!orderIdsArray.includes(orderId)) {
      orderIdsArray.push(orderId);
      localStorage.setItem("orderIds", JSON.stringify(orderIdsArray));
    }
  };

  useEffect(() => {
    if (param.orderId) {
      dispatch(fetchOrdersList(param.orderId) as any);
      storeOrderId(param.orderId);
    }
  }, [dispatch, param]);

  useEffect(() => {
    if (!checkoutExpiry) {
      dispatch(addCheckoutExpiry());
    }
  }, [checkoutExpiry, dispatch]);

  if (loading) {
    return (
      <span>Loading...</span>
    )
  }

  return (
    <section className="w-full flex items-center mt-5 flex-col h-screen overflow-y-scroll">
      <div className="w-[80%] md:w-[50%] lg:w-[30%] inline-flex items-center spac mb-2">
        <img
          className="h-20 w-20 rounded-full"
          src="https://marketplace.canva.com/EAFXIvlL2Ns/2/0/1600w/canva-brown-and-black-vintage-food-restaurant-logo-YASJJho2Kzw.jpg"
          alt="restaurant"
        />
        <span className="text-lg font-semibold ml-3">{restaurantName}</span>
      </div>
      <section className="w-[80%] md:w-[50%] lg:w-[30%] pt-6 px-4">
        {orders?.map(({ id: orderId, items, order_date, total_amount, order_number }) => {
          return (
            <div key={orderId?.toString()} className="">
              <p className="font-semibold text-lg">#{order_number}</p>
              <p className="text-sm mb-5">
                Arrived at{" "}
                <time className="text-sm">
                  {dayjs(order_date).format("hh:mm a")}
                </time>
              </p>
              <p className="text-base font-semibold">
                {items?.length} item in this order
              </p>
              <div className="border-b-8 pb-4 mb-4">
                {items?.map(
                  ({ id: itemId, nameOfDish, price, quantity, type }) => {
                    return (
                      <div
                        id={itemId.toString()}
                        className="flex items-end text-sm flex-row justify-between w-full py-2"
                      >
                        <div>
                          <p>{nameOfDish}</p>
                          <p> x {quantity}</p>
                        </div>
                        <p className="font-semibold">₹ {price}</p>
                      </div>
                    );
                  }
                )}
              </div>
              <div>
                <h3 className="text-base font-semibold border-b mb-2 pb-2">
                  Bill Details
                </h3>
                <div className="flex flex-row justify-between py-1">
                  <h3 className="text-sm">MRP</h3>
                  <p className="text-sm">₹ {total_amount}</p>
                </div>
                <div className="flex flex-row justify-between py-1">
                  <h3 className="text-sm">Product discount</h3>
                  <p className="text-sm">₹ 0</p>
                </div>
                <div className="flex flex-row justify-between py-1">
                  <h3 className="text-sm">Item total</h3>
                  <p className="text-sm">₹ 0</p>
                </div>
                <div className="flex flex-row justify-between py-1">
                  <h3 className="text-sm">Handling charge</h3>
                  <p className="text-sm">₹ 0</p>
                </div>
                <div className="flex flex-row justify-between py-1">
                  <h3 className="text-sm">Delivery charges</h3>
                  <p className="text-sm">free</p>
                </div>
                <div className="flex flex-row justify-between py-2 font-semibold">
                  <h3 className="text-sm">Bill total</h3>
                  <p className="text-sm">₹ {total_amount}</p>
                </div>
              </div>
            </div>
          );
        })}
        <button
          className="bg-slate-500 w-full text-white py-2 mt-4 rounded-md"
          onClick={onCreateNewOrderHandler}
        >
          Create new order
        </button>
      </section>
    </section>
  );
};

export default CheckoutSuccessPage;
