import { createSlice } from "@reduxjs/toolkit";
import { fetchOrdersList } from "./orders.api";

interface OrderItemTypes {
  id: number;
  type: string;
  price: number;
  quantity: number;
  nameOfDish: string;
}

export interface OrderTypes {
  id: number;
  restaurant_id: number;
  order_date: string;
  total_amount: string;
  payment_method: string;
  transaction_id: string;
  items: OrderItemTypes[];
  payment_status: string;
  order_number: string;
}

type OrdersTypes = {
  orders: Array<OrderTypes>;
  loading: boolean;
  error: string | undefined;
};

const initialState: OrdersTypes = {
  orders: [],
  loading: false,
  error: "",
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrdersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrdersList.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(fetchOrdersList.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message;
      });
  },
});

export default ordersSlice.reducer;
