import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { clearCart } from "./cart.slice";
import { RootState } from "../store";

export const checkoutProducts = createAsyncThunk("cart/checkout-products", async (checkoutData: any, {rejectWithValue, fulfillWithValue, dispatch, getState}) => {
    try {
    const state = getState() as RootState;
    const id = state.profile.id;
    const orderType = state.cart.orderType;

    const config = {
        method: "post",
        url: 'https://api.deep-link.ai/api/transactions/cca-transaction',
        headers: {
            'Content-Type': 'application/json'
        },
        data: { checkoutData, restaurant_id: id, order_type: orderType }
    };
    
    dispatch(clearCart());
    const response = await axios(config);
    return response.data;
    } catch (error) {
        console.log('error', error);
        return rejectWithValue(error);
    }
});
