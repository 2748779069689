import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { selectIsMenuOpen } from "../../store/cart/cart.selector";
import { setIsMenuOpen } from "../../store/cart/cart.slice";
import { AppDispatch } from "../../store/store";
import { useNavigate } from "react-router-dom";

const MenuPage = ({ restaurantId }: { restaurantId: string }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const menuOpen = useSelector(selectIsMenuOpen, shallowEqual);

  const onMenuClose = () => {
    dispatch(setIsMenuOpen());
  };

  const handleOrderHistory = () => {
    navigate(`/order-list/${restaurantId}`);
    onMenuClose();
  };

  return (
    <div
      className={`fixed inset-0 overflow-hidden ${
        menuOpen ? "z-50" : "z-0"
      } transition-transform duration-300 ${
        menuOpen ? "translate-x-0" : "translate-x-full"
      }`}
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="false"
    >
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div
            onClick={onMenuClose}
            className={`fixed inset-0 bg-gray-500 bg-opacity-75  ${
              menuOpen
                ? "transition-opacity duration-1000 opacity-100"
                : "opacity-0"
            }`}
          />
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div className="pointer-events-auto w-screen max-w-md md:max-w-sm">
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                  <div className="flex items-start justify-between">
                    <h2
                      className="text-lg font-medium text-gray-900 font-playfair"
                      id="slide-over-title"
                    >
                      Menu
                    </h2>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                        onClick={onMenuClose}
                      >
                        <span className="font-playfair underline">CLOSE</span>
                      </button>
                    </div>
                  </div>
                  <div className="mt-8">
                    <ul className="-my-6 divide-y divide-gray-200">
                      <li className="flex py-6" onClick={handleOrderHistory}>
                        <div className="ml-4 flex flex-1 flex-col">
                          <div className="flex justify-between text-base font-medium text-primary underline">
                            <span className="truncate overflow-hidden w-1/2">
                              Order history
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="px-4 py-6 sm:px-6 flex justify-between items-center">
                  <p className="text-lg font-semibold">Contact support</p>
                  <a href="tel:+918096490141" className="underline text-blue-500 my-2 mx-3">+91 8096490141</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuPage;
