import { createSlice } from "@reduxjs/toolkit";
import { checkoutProducts } from "./cart.api";
import dayjs from "dayjs";

export interface Item {
  nameOfDish: string;
  type: string;
  price: number;
  id: string;
}

type CartState = {
  items: Array<Item>;
  isCartOpen: boolean;
  isMenuOpen: boolean;
  checkoutData: any;
  loading: boolean;
  error: string | undefined;
  checkoutMessage: string;
  orderId: number | null;
  orderType: string;
  checkoutExpiry: string | null;
  isMessageShown: boolean;
};

const initialState: CartState = {
  items: [],
  isCartOpen: false,
  isMenuOpen: false,
  checkoutData: "",
  loading: false,
  error: "",
  checkoutMessage: "",
  orderId: null,
  orderType: "",
  checkoutExpiry: null,
  isMessageShown: false,
};

const addItemsToCart = (cartItems: any, productToAdd: any) => {
  const existingCartItems = cartItems?.find(
    (cartItem: any) => cartItem.id === productToAdd.id
  );

  if (existingCartItems) {
    return cartItems?.map((cartItem: any) =>
      cartItem.id === productToAdd.id
        ? { ...cartItem, quantity: cartItem.quantity + productToAdd.quantity }
        : cartItem
    );
  }
  return [...cartItems, { ...productToAdd }];
};

const removeItemsFromCart = (cartItems: any, cartItemToRemove: any) => {
  const existingCartItem = cartItems.find(
    (cartItem: any) => cartItem.id === cartItemToRemove.id
  );
  if (existingCartItem && existingCartItem.quantity === 1) {
    return cartItems.filter(
      (cartItem: any) => cartItem.id !== cartItemToRemove.id
    );
  }
  return cartItems?.map((cartItem: any) =>
    cartItem.id === cartItemToRemove.id
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};

const clearCartItem = (cartItems: any, clearCartItem: any) =>
  cartItems.filter((cartItem: any) => cartItem.id !== clearCartItem.id);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state: CartState, action) => {
      const { cart, product } = action.payload;
      state.items = addItemsToCart(cart, product);
    },
    removeFromCart: (state, action) => {
      const { cart, product } = action.payload;
      state.items = removeItemsFromCart(cart, product);
    },
    clearItemFromCart: (state, action) => {
      const { cart, product } = action.payload;
      state.items = clearCartItem(cart, product);
    },
    clearCart: (state) => {
      state.items = [];
    },
    setIsCartOpen: (state) => {
      state.isCartOpen = !state.isCartOpen;
    },
    setIsMenuOpen: (state) => {
      state.isMenuOpen = !state.isMenuOpen;
    },
    clearCheckoutInfo: (state) => {
      state.orderId = null;
      state.orderType = "";
      state.checkoutData = "";
    },
    addCheckoutExpiry: (state) => {
      state.checkoutExpiry = dayjs(new Date()).add(1, "hour").toString();
    },
    removeCheckoutExpiry: (state) => {
      state.checkoutExpiry = null;
    },
    setMessageShown: (state, action) => {
      state.isMessageShown = action.payload;
    },
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(checkoutProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkoutProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.checkoutData = action.payload;
      })
      .addCase(checkoutProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message;
      });
  },
});

export const {
  addToCart,
  removeFromCart,
  clearItemFromCart,
  setIsCartOpen,
  clearCart,
  clearCheckoutInfo,
  addCheckoutExpiry,
  removeCheckoutExpiry,
  setIsMenuOpen,
  setMessageShown,
  setOrderType,
} = cartSlice.actions;

export default cartSlice.reducer;
