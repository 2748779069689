import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { selectCartCount } from "../../store/cart/cart.selector";
import { setIsCartOpen } from "../../store/cart/cart.slice";

const ViewCart = () => {
  const dispatch = useDispatch();

  const cartCount = useSelector(selectCartCount, shallowEqual);

  const onCart = () => {
    dispatch(setIsCartOpen());
  };

  return (
    <>
      {cartCount > 0 && (
        // <div className="fixed bottom-0 left-0 w-full shadow-lg inline-flex justify-center items-center py-2 bg-white">
          <div className="bg-primary hover:bg-[#45ad68] text-white py-2 px-4 rounded-t-md cursor-pointer z-10 fixed bottom-0 w-[100%]" onClick={onCart}>
            <div className="w-full inline-flex justify-between">
              <span>{cartCount ? cartCount : 0} {cartCount === 1 ? 'item' : 'items'} added</span>
              <span className="text-white">View cart</span>
            </div>
          </div>
        // </div>
      )}
    </>
  );
};

export default ViewCart;
