import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchRestaurantProfile = createAsyncThunk("profile/fetch-restaurant-detail", async (restaurantId: string, {rejectWithValue, fulfillWithValue, dispatch}) => {
    try {
    const response = await fetch(`https://api.deep-link.ai/api/restaurant/profile/${restaurantId}`)
    const result = await response.json();
    return result[0];
    } catch (error) {
        return rejectWithValue(error);
    }
});
