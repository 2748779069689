import { createSlice } from "@reduxjs/toolkit";
import { fetchProductList } from "./products.api";

export enum TypeOfDish {
  Veg,
  NonVeg,
  Egg,
}

export interface ProductsState {
  products: Array<{
    menuheading: string;
    menuimage: string;
    nameofdishes: Array<{
      nameOfDish: string;
      type: string;
      price: number;
      id: string;
      quantity?: number;
    }>;
  }>;
  loading: boolean;
  error: any;
}

const initialState: ProductsState = {
  products: [],
  loading: false,
  error: '',
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    //TODO: add quantity and increase the quantity of the product
    //Check if the product exist in the cart
    addQuantityToProduct: (state: ProductsState, action) => {
      const { menuheading, id, quantity } = action.payload;
      state.products[menuheading].nameofdishes[id].quantity = quantity;
    },
    setProductItems: (state: ProductsState, action) => {
      // const products = action.payload;
      // state.products = products
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProductList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductList.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload as any;
      })
      .addCase(fetchProductList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  }
});

export const { setProductItems } = productsSlice.actions;

export default productsSlice.reducer;
