import { createSlice } from "@reduxjs/toolkit";
import { fetchRestaurantProfile } from "./profile.api";

export interface ProfileState {
  name: string;
  email: string;
  gstin: string;
  phone_number: string;
  id: number;
  image_url: string;
  loading: boolean;
  error: any;
}

const initialState: ProfileState = {
  name: '',
  email: '',
  gstin: '',
  phone_number: '',
  image_url: '',
  id: 0,
  loading: false,
  error: '',
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRestaurantProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRestaurantProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.email = action.payload.email;
        state.name = action.payload.name;
        state.phone_number = action.payload.phone_number;
        state.gstin = action.payload.gstin;
        state.id = action.payload.id;
        state.image_url = action.payload.image_url;
      })
      .addCase(fetchRestaurantProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  }
});

export default profileSlice.reducer;
