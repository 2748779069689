import {createSelector} from "reselect";
import { RootState } from "../store";

const selectorProfileReducer = ({profile}: RootState) => profile;

export const selectProfileName = createSelector(
    [selectorProfileReducer],
    (profile) => profile.name
)

export const selectProfileImage = createSelector(
    [selectorProfileReducer],
    (profile) => profile.image_url
)

export const selectProfileId = createSelector(
    [selectorProfileReducer],
    (profile) => profile.id
)
