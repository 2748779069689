import React from "react";
import { NavLink, useParams } from "react-router-dom";

import {ReactComponent as CancelIcon} from "../../assets/images/cancel.svg";

const CheckoutFailurePage = () => {
  const param = useParams();

  return (
    <div className="inline-flex justify-end md:items-end items-center w-screen h-screen">
      <div className="w-screen inline-flex items-center justify-center py-3 flex-col h-full">
        <CancelIcon className="md:w-52 md:h-52 h-24 w-24" />
        <span className="md:text-3xl text-lg text-center">Something went wrong we couldn't process your payment</span>
        <NavLink to={`/${param.restaurantId}`} className="underline visited:text-violet-600 text-blue-600">Home</NavLink>
      </div>
    </div>
  );
};

export default CheckoutFailurePage;
