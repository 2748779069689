import { createSelector } from "reselect";

import { RootState } from "../store";

const selectorCartReducer = ({ cart }: RootState) => cart;

export const selectCartItems = createSelector(
  [selectorCartReducer],
  (cart) => cart.items
);

export const selectOrderType = ({cart}: RootState) => cart?.orderType;

export const checkoutUrl = ({cart}: any) => cart?.checkoutData;

export const selectOrderId = ({cart}: any) => cart?.orderId;

export const selectCheckoutExpiry = ({cart}: any) => cart?.checkoutExpiry;

export const selectCartCount = createSelector([selectCartItems], (cartItems) =>
  cartItems?.reduce(
    (total: number, cartItem: any) => total + cartItem?.quantity,
    0
  )
);

export const selectCartTotal = createSelector([selectCartItems], (cartItems) =>
  cartItems?.reduce(
    (total: number, cartItem: any) =>
      total + cartItem?.quantity * cartItem.price,
    0
  )
);

export const selectIsCartOpen = createSelector(
  [selectorCartReducer],
  (cart) => cart.isCartOpen
);

export const selectIsMenuOpen = createSelector(
  [selectorCartReducer],
  (cart) => cart.isMenuOpen
);

export const selectIsMessageShown = createSelector(
  [selectorCartReducer],
  (cart) => cart.isMessageShown
);