import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchOrdersList = createAsyncThunk(
  "products/fetch-products",
  async (
    orderId: number | string,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await fetch(
        `https://api.deep-link.ai/api/orders/get-order/${orderId}`
      );
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);