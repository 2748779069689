import React, { useMemo } from "react";
import Counter from "../counter/counter.component";
import { Item } from "../../store/cart/cart.slice";
import 'animate.css'

type ProductListType = {
  productsList: Array<{
    menuheading: string;
    menuimage: string;
    nameofdishes: Array<{
      nameOfDish: string;
      type: string;
      price: number;
      id: string;
      quantity?: number;
    }>;
  }>;
  handleAccordionClick: (accordion: string) => void;
  activeAccordion: string | null;
  addToCart: (product: any) => void;
  removeFromCart: (product: any) => void;
  cartItems: any[];
};

const ProductList = ({
  productsList,
  handleAccordionClick,
  activeAccordion,
  addToCart,
  removeFromCart,
  cartItems,
}: ProductListType) => {
  return (
    <>
      {productsList?.map(({ menuheading, nameofdishes, menuimage }) => {
        return (
          <>
            <h2>
              <button
                type="button"
                className="flex items-center justify-between w-full py-2 font-medium rtl:text-right text-gray-500 border-b border-light-grey dark:border-dark-grey dark:text-gray-400 gap-3"
                onClick={() => handleAccordionClick(menuheading)}
              >
                <div className="inline-flex flex-row items-center">
                  <img
                    alt="soup-veg"
                    src={menuimage}
                    className="w-10 h-10 overflow-hidden rounded-md object-cover"
                  />
                  <span className="text-black ms-3">{menuheading}</span>
                </div>
                <svg
                  data-accordion-icon
                  className="w-3 h-3 rotate-180 shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div
              id={menuheading}
              className={`${
                activeAccordion === menuheading ? "block" : "hidden"
              }`}
              aria-labelledby={menuheading}
            >
              <div className="py-5 border-b border-light-grey dark:border-dark-grey px-2">
                {nameofdishes?.map((item) => (
                  <Dishes {...{ item, addToCart, removeFromCart, cartItems }} />
                ))}
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

type DishesProps = {
  item: Item;
  addToCart: (product: any) => void;
  removeFromCart: (product: any) => void;
  cartItems: any[];
};

const Dishes = ({
  item,
  addToCart,
  removeFromCart,
  cartItems,
}: DishesProps) => {
  const { nameOfDish, price, type, id } = item;

  const quantity = useMemo(() => {
    return cartItems?.find((e) => e?.id === id)?.quantity ?? 0;
  }, [cartItems, id]);

  return (
    <div className="inline-flex flex-row justify-between w-full my-2">
      <div className="inline-flex flex-col">
      <div className="inline-flex flex-row items-center">
        <img
          alt="veg"
          src={
            type.toLowerCase() === "veg"
              ? "/assets/images/veg_icon.png"
              : "/assets/images/nonveg_icon.png"
          }
          className="h-3.5 w-3.5"
        />
        <span className="ms-2">{nameOfDish}</span>
      </div>
      <span className="mb-1 text-xs text-gray-500">₹ {price}</span>
      </div>
      <div className="inline-flex flex-col items-center">
        {quantity ? (
          <Counter
            quantity={quantity}
            onAdd={() => addToCart({ id, nameOfDish, price, type })}
            onRemove={() =>
              removeFromCart({
                id,
                nameOfDish,
                price,
                type,
                quantity,
              })
            }
          />
        ) : (
          <AddButton onAdd={() => addToCart({ id, nameOfDish, price, type })} />
        )}
      </div>
    </div>
  );
};

const AddButton = ({ onAdd }: { onAdd: () => void }) => (
  <button
    onClick={onAdd}
    className="h-10 w-32 px-7 bg-primary rounded-md text-white"
  >
    Add
  </button>
);

export default ProductList;