import {combineReducers} from '@reduxjs/toolkit';

import productsSlice from "./products/products.slice";
import cartSlice from './cart/cart.slice';
import profileSlice from './profile/profile.slice';
import ordersSlice from './orders/orders.slice';

const rootReducer = combineReducers({
    products: productsSlice,
    cart: cartSlice,
    profile: profileSlice,
    orders: ordersSlice,
})

export default rootReducer;