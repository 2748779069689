import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  selectCartTotal,
  selectIsCartOpen,
  selectCartItems,
  selectOrderType,
} from "../../store/cart/cart.selector";
import { setIsCartOpen } from "../../store/cart/cart.slice";
import CartList from "../../components/cart-list/cart-list.component";
import { checkoutProducts } from "../../store/cart/cart.api";
import { AppDispatch } from "../../store/store";

const CartPage = ({handleCheckout}: {handleCheckout: () => void}) => {
  const dispatch = useDispatch<AppDispatch>();

  const checkoutTotal = useSelector(selectCartTotal, shallowEqual);
  const cartOpen = useSelector(selectIsCartOpen, shallowEqual);
  const cartItems = useSelector(selectCartItems, shallowEqual);
  const orderType = useSelector(selectOrderType, shallowEqual);

  const onCartClose = () => {
    dispatch(setIsCartOpen());
  };

  const onCheckout = async () => {
    if (cartItems.length > 0) {
      handleCheckout();
    }
    onCartClose();
  };

  useEffect(() => {
    if ((orderType === 'Dine In' || orderType === 'Take Away') && cartItems.length > 0) {
      dispatch(checkoutProducts(cartItems));
    }
  }, [orderType, dispatch, cartItems]);

  return (
    <div
      className={`fixed inset-0 overflow-hidden ${
        cartOpen ? "z-50" : "z-0"
      } transition-transform duration-300 ${
        cartOpen ? "translate-x-0" : "translate-x-full"
      }`}
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="false"
    >
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div
            onClick={onCartClose}
            className={`fixed inset-0 bg-gray-500 bg-opacity-75  ${
              cartOpen
                ? "transition-opacity duration-1000 opacity-100"
                : "opacity-0"
            }`}
          />
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div className="pointer-events-auto w-screen max-w-md md:max-w-sm">
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                  <div className="flex items-start justify-between">
                    <h2
                      className="text-lg font-medium text-gray-900 font-playfair"
                      id="slide-over-title"
                    >
                      Shopping cart
                    </h2>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                        onClick={onCartClose}
                      >
                        <span className="font-playfair underline">CLOSE</span>
                      </button>
                    </div>
                  </div>
                  <div className="mt-8">
                    <CartList cartItems={cartItems} />
                  </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                  <div className="flex justify-between text-base font-medium text-gray-900 font-playfair">
                    <p>Total</p>
                    <p>₹ {Math.trunc(Math.floor(checkoutTotal))}</p>
                  </div>
                  {/* <p className="mt-0.5 text-sm text-gray-500 font-playfair">
                    Shipping and taxes calculated at checkout.
                  </p> */}
                  <div className="mt-6">
                    <button
                      type="button"
                      className="
                      border focus:outline-none
                      focus:ring-4 font-medium rounded-lg 
                      text-sm px-5 py-2.5 mr-2 bg-gray-800 text-white border-gray-600
                    hover:bg-gray-700 hover:border-gray-600 
                    focus:ring-gray-700 mt-1 w-full"
                      onClick={onCheckout}>
                      CHECKOUT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
