import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {ReactComponent as EmptyList} from "../../assets/images/empty-list.svg";

const OrderListPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [orderList, setOrderList] = useState<Array<string>>([]);

  const fetchLocallyStoredOrders = async () => {
    try {
      const storedOrderIds = localStorage.getItem("orderIds") as string;
      const orderIdsArray: string[] = storedOrderIds
        ? JSON.parse(storedOrderIds)
        : [];
      setOrderList(orderIdsArray);
    } catch (error) {
      console.log("Async storage error: ", error);
    }
  };

  const onCreateNewOrderHandler = () => {
    navigate(`/${params.restaurantId}`);
  };

  const navigateOrderDetail = (orderId: string) => {
    navigate(`/checkout-success/${orderId}`);
  }

  useEffect(() => {
    fetchLocallyStoredOrders();
  }, []);


  if (orderList.length === 0) {
    return (
      <div className="inline-flex items-center justify-center w-screen h-screen flex-col">
        <EmptyList className="w-96 h-96" />
        <span className="text-xl text-primary">No orders yet</span>
        <span className="text-sm text-black">Looks like you haven't made your orders yet</span>
        <button
          className="bg-primary text-white py-2 mt-4 rounded-md px-7"
          onClick={onCreateNewOrderHandler}
        >
          Place an order
        </button>
      </div>
    )
  }

  return (
    <>
      <h2>
        {orderList.map((item, index) => (
          <button
            type="button"
            className="flex items-center justify-between w-full py-2 font-medium rtl:text-right text-gray-500 border-b border-light-grey dark:border-dark-grey dark:text-gray-400 gap-3"
            key={`${item}_${index}`}
            onClick={() => navigateOrderDetail(item)}
          >
            <div className="inline-flex flex-row items-center">
              <span className="text-black ms-3">Order: #{item}</span>
            </div>
            <span className="text-primary underline mr-2">VIEW DETAIL</span>
          </button>
        ))}
      </h2>
    </>
  );
};

export default OrderListPage;
