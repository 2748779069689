import React from "react";

type CounterProps = {
  quantity: number;
  onRemove: () => void;
  onAdd: () => void;
};

const Counter = ({ quantity, onRemove, onAdd }: CounterProps) => {
  return (
    <div className="flex flex-row h-10 w-32 rounded-lg relative bg-transparent border-primary border">
      <button
        data-action="decrement"
        className="text-primary h-full w-20 rounded-l cursor-pointer outline-none"
      >
        <span className="m-auto text-2xl font-thin" onClick={onRemove}>
          −
        </span>
      </button>
      <span className="focus:outline-none text-center w-full font-semibold text-md text-black md:text-basecursor-default flex items-center justify-center outline-none">
        {quantity}
      </span>
      <button
        data-action="increment"
        className="text-primary h-full w-20 rounded-r cursor-pointer"
      >
        <span className="m-auto text-2xl font-thin" onClick={onAdd}>
          +
        </span>
      </button>
    </div>
  );
};

export default Counter;
