import { createBrowserRouter } from "react-router-dom";

import Home from "../pages/home/home.page";
import CheckoutSuccessPage from "../pages/checkout-success/checkout-success.page";
import CheckoutFailurePage from "../pages/checkout-failed/checkout-failed.page";
import OrderListPage from "../pages/order-list/order-list.page";

const router = createBrowserRouter(
  [
    {
      path: "/",
      Component: Home,
      children: [
        {
          id: "Home",
          path: "/:id",
          Component: Home,
        },
      ],
    },
    {
      id: "CheckoutSuccessPage",
      path: "/checkout-success/:orderId",
      Component: CheckoutSuccessPage,
    },
    {
      id: "CheckoutFailurePage",
      path: "/checkout-failed/:restaurantId",
      Component: CheckoutFailurePage,
    },
    {
      id: "OrderListPage",
      path: "/order-list/:restaurantId",
      Component: OrderListPage,
    }
  ],
  {
    future: {
      v7_partialHydration: true,
    },
    hydrationData: {
      loaderData: {
        root: "ROOT DATA",
        // No index data provided
      },
    },
  }
);

export default router;
