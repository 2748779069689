import React, { memo } from "react";
import { useDispatch } from "react-redux";

import Counter from "../counter/counter.component";
import {
  addToCart,
  removeFromCart,
  clearItemFromCart,
} from "../../store/cart/cart.slice";

const CartList = ({ cartItems }: any) => {
  const dispatch = useDispatch();

  const onAddItemToCart = (cartItem: any) => {
    const updateProduct = { ...cartItem, quantity: 1 };
    dispatch(addToCart({ cart: cartItems, product: updateProduct }));
  };

  const onRemoveItemFromCart = (cartItem: any) => {
    dispatch(removeFromCart({ cart: cartItems, product: cartItem }));
  };

  const onClearItemFromCart = (cartItem: any) => {
    dispatch(clearItemFromCart({ cart: cartItems, product: cartItem }));
  };

  const truncateDishName = (nameOfDish: string) => {
    const maxLength = 20;
    if (nameOfDish.length > maxLength) {
      return nameOfDish.slice(0, maxLength) + "...";
    }

    return nameOfDish;
  };

  return (
    <div className="flow-root">
      <ul className="-my-6 divide-y divide-gray-200">
        {cartItems?.map((cartItem: any) => (
          <li
            className="flex py-6"
            key={`${cartItem?.id}-${cartItem?.nameOfDish}`}
          >
            <div className="ml-4 flex flex-1 flex-col">
              <div className="flex justify-between text-base font-medium text-gray-900">
                <span className="overflow-hidden">
                  {truncateDishName(cartItem.nameOfDish)}
                </span>
                <p className="ml-4">₹ {cartItem.price}</p>
              </div>
              <div className="flex flex-1 items-end justify-between text-sm">
                <Counter
                  quantity={cartItem.quantity}
                  onAdd={() => onAddItemToCart(cartItem)}
                  onRemove={() => onRemoveItemFromCart(cartItem)}
                />
                <div className="flex">
                  <button
                    type="button"
                    className="font-medium text-primary hover:text-orange-400"
                    onClick={() => onClearItemFromCart(cartItem)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(CartList);
